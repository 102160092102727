import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import * as HDS from "hds-react";
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/visual-assets/icons/list",
  "title": "Icons - List of all icons"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "list-of-all-icons",
      "style": {
        "position": "relative"
      }
    }}>{`List of all icons`}<a parentName="h2" {...{
        "href": "#list-of-all-icons",
        "aria-label": "list of all icons permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "arrows-and-operators",
      "style": {
        "position": "relative"
      }
    }}>{`Arrows and operators`}<a parentName="h3" {...{
        "href": "#arrows-and-operators",
        "aria-label": "arrows and operators permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Core`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common Common usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconAngleDown /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`angle-down`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconAngleDown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dropdown open`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconAngleLeft /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`angle-left`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconAngleLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconAngleRight /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`angle-right`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconAngleRight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconAngleUp /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`angle-up`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconAngleUp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dropdown close`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowDown /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-down`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowDown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowLeft /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-left`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Previous`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowRight /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-right`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowRight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Next`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowUp /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-up`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowUp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowBottomLeft /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-bottom-left`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowBottomLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowBottomRight /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-bottom-right`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowBottomRight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowTopLeft /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-top-left`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowTopLeft`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowTopRight /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-top-right`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowTopRight`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCross /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Close`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCrossCircle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cross-circle `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCrossCircle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCrossCircleFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cross-circle-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCrossCircleFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove alternative`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMinus /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`minus `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMinus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMinusCircle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`minus-circle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMinusCircle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMinusCircleFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`minus-circle-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMinusCircleFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlus /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`plus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlusCircle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`plus-circle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlusCircle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlusCircleFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`plus-circle-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlusCircleFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add alternative`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Arrows and operators icons]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "notifications-and-expressions",
      "style": {
        "position": "relative"
      }
    }}>{`Notifications and expressions`}<a parentName="h3" {...{
        "href": "#notifications-and-expressions",
        "aria-label": "notifications and expressions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Core`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common Common usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconAlertCircle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`alert-circle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconAlertCircle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconAlertCircleFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`alert-circle-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconAlertCircleFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert alternative`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCheck /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`check`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCheck`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCheckCircle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`check-circle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCheckCircle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCheckCircleFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`check-circle-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCheckCircleFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success alternative`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCustomerBotNegative /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`customer-bot-negative`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCustomerBotNegative`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot customer service, negative feedback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCustomerBotNeutral /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`customer-bot-neutral`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCustomerBotNeutral`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot customer service, neutral feedback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCustomerBotPositive /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`customer-bot-positive`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCustomerBotPositive`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bot customer service, positive feedback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconInfoCircle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`info-circle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconInfoCircle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconInfoCircleFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`info-circle-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconInfoCircleFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Information alternative`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconError /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconError`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Error`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconErrorFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`error-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconErrorFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Error alternative`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconFaceNeutral /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`face-neutral`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconFaceNeutral`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconFaceSad /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`face-sad`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconFaceSad`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconFaceSmile /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`face-smile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconFaceSmile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconHeart /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`heart`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconHeart`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Like`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconHeartFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`heart-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconHeartFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Like selected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconQuestionCircle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`question-circle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconQuestionCircle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Help, Tooltip`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconQuestionCircleFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`question-circle-fill `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconQuestionCircleFill `}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Help alternative`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconStar /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`star`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconStar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rating`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconStarFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`star-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconStarFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rating selected`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconThumbsDown /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`thumbs-down`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconThumbsDown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dislike, No (E.g. in voting)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconThumbsDownFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`thumbs-down-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconThumbsDownFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dislike, No (E.g. in voting)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconThumbsUp /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`thumbs-up`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconThumbsUp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Like, Yes (E.g. in voting)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconThumbsUpFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`thumbs-up-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconThumbsUpFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Like, Yes (E.g. in voting)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Notifications and expressions icons]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "navigation",
      "style": {
        "position": "relative"
      }
    }}>{`Navigation`}<a parentName="h3" {...{
        "href": "#navigation",
        "aria-label": "navigation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Core`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconHome /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`home`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconHome`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Home`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconHomeSmoke /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`home-smoke`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconHomeSmoke`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMenuHamburger /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`menu-hamburger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMenuHamburger`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile menu`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMenuDots /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`menu-dots`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMenuDots`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional operations`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconRefresh /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`refresh`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconRefresh`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Refresh page or content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSignin /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`signin`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSignin`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sign in`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSignout /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`signout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSignout`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sign out`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSitemap /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sitemap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSitemap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sitemap`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSearch /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`search`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSearch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Search`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconUser /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`user`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconUser`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Username`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Navigation icons]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "actions-and-settings",
      "style": {
        "position": "relative"
      }
    }}>{`Actions and settings`}<a parentName="h3" {...{
        "href": "#actions-and-settings",
        "aria-label": "actions and settings permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Core`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconBell /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bell`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconBell`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Notification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconBellCrossed /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bell-crossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconBellCrossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Disable notifications`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCogwheel /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cogwheel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCogwheel`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Settings`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCopy /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`copy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCopy`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Copy`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconDownload /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`download`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconDownload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Download file to device`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconDownloadCloud /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`download-cloud`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconDownloadCloud`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Download from cloud service`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconDrag /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`drag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconDrag`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Drag handle for draggable elements`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconEye /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`eye`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconEye`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconEyeCrossed /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`eye-crossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconEyeCrossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hide content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconLock /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lock`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconLock`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content locked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconLockOpen /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lock-open`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconLockOpen`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content unlocked`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSaveDiskette /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`save-diskette`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSaveDiskette`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Saving`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSaveDisketteFill /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`save-diskette-fill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSaveDisketteFill`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Saving alternative`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconShare /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`share`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconShare`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Share content or link`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconUpload /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`upload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconUpload`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Upload file to server`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconUploadCloud /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`upload-cloud`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconUploadCloud`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Upload to cloud service`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconZoomIn /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zoom-in`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconZoomIn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Increase zoom level`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconZoomOut /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zoom-out`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconZoomOut`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Decrease zoom level`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconZoomText /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zoom-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconZoomText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text zoom settings`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 4:Actions and settings icons]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "forms-and-information",
      "style": {
        "position": "relative"
      }
    }}>{`Forms and information`}<a parentName="h3" {...{
        "href": "#forms-and-information",
        "aria-label": "forms and information permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Core`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowRedo /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-redo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowRedo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Redo edits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconArrowUndo /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`arrow-undo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconArrowUndo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Undo edits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCalendar /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`calendar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCalendar`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCalendarClock /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`calendar-clock`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCalendarClock`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date and time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCalendarCross /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`calendar-cross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCalendarCross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCalendarPlus /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`calendar-plus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCalendarPlus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCalendarRecurring /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`calendar-recurring`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCalendarRecurring`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Recurring date`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconChildren /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`children`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconChildren`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Children`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconClock /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clock`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconClock`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconClockCross /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clock-cross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconClockCross`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Remove time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconClockPlus /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`clock-plus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconClockPlus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add time`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCompany /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`company`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCompany`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Company name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconDocument /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`document`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconDocument`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Document / PDF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconEntrepreneur /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`entrepreneur`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconEntrepreneur`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Work status`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconEnvelope /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`envelope`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconEnvelope`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email address / message`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconEvent /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`event`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconEvent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconFamily /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`family`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconFamily`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Information about family`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconGlobe /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`globe`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconGlobe`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Web page address`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconGroup /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`group`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconGroup`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Group`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconLayers /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`layers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconLayers`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choose layers`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconLink /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`link`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconLink`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconLinkExternal /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`link-external`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconLinkExternal`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Link to another website / opens in other tab`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconLocate /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`locate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconLocate`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Show users location`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconLocation /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`location`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconLocation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Location`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMap /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`map`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMap`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Map`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMover /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Immigrant information`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconOccupation /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`occupation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconOccupation`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Occupation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPaperclip /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`paperclip`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPaperclip`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Attachment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPen /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pen`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPen`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Edit content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPenLine /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pen-line`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPenLine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fill content`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPersonFemale /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`person-female`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPersonFemale`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender female`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPersonGenderless /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`person-genderless`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPersonGenderless`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Non-binary gender`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPersonMale /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`person-male`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPersonMale`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gender male`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPersonWheelchair /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`person-wheelchair`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPersonWheelchair`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accessibility`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPhone /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`phone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPhone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPhoto /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`photo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPhoto`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Photo or image file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPhotoPlus /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`photo-plus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPhotoPlus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add photo or image file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSenior /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`senior`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSenior`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Senior`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSliders /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sliders`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSliders`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filter settings`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSort /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sort`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSort`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggle sorting`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSortAscending /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sort-ascending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSortAscending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sorting from smallest to largest`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSortDescending /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sort-descending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSortDescending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sorting from largest to smallest`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSortAlphabeticalAscending /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sort-alphabetical-ascending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSortAlphabeticalAscending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Alphabetical sorting from A to Z`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSortAlphabeticalDescending /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sort-alphabetical-descending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSortAlphabeticalDescending`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Alphabetical sorting from Z to A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSpeechbubble /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`speechbubble`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSpeechbubble`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat / comment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSpeechbubbleText /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`speechbubble-text`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSpeechbubbleText`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chat / comment`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTextBold /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text-bold`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTextBold`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text bold`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTextItalic /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text-italic`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTextItalic`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text italic`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTextTool /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`text-tool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTextTool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Enable text editing`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTrash /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`trash`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTrash`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Trash`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTraveler /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`traveler`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTraveler`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Traveler`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconYouth /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`youth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconYouth`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Youth`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 5:Forms and information icons]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "media-and-devices",
      "style": {
        "position": "relative"
      }
    }}>{`Media and devices`}<a parentName="h3" {...{
        "href": "#media-and-devices",
        "aria-label": "media and devices permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Core`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCamera /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`camera`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCamera`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Take photo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconDisplay /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`display`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconDisplay`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Display / desktop version`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconHeadphones /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`headphones`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconHeadphones`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Human customer service`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMicrophone /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`microphone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMicrophone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Microphone / sound recording`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMicrophoneCrossed /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`microphone-crossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMicrophoneCrossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Microphone disabled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconMobile /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconMobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile device / version`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlaybackFastforward /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`playback-fastforward`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlaybackFastforward`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fast forward`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlaybackNext /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`playback-next`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlaybackNext`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skip to next track`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlaybackPause /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`playback-pause`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlaybackPause`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Pause`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlaybackPlay /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`playback-play`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlaybackPlay`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Play`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlaybackPrevious /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`playback-previous`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlaybackPrevious`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Skip to previous track`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlaybackRecord /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`playback-record`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlaybackRecord`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Record`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlaybackRewind /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`playback-rewind`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlaybackRewind`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rewind`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPlaybackStop /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`playback-stop`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPlaybackStop`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Stop`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPodcast /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`podcast`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPodcast`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Podcast`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconPrinter /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`printer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconPrinter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Print`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVideocamera /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`videocamera`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVideocamera`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVideocameraCrossed /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`videocamera-crossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVideocameraCrossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video disabled`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVolumeHigh /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`volume-high`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVolumeHigh`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Volume control / Volume level set to high`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVolumeLow /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`volume-low`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVolumeLow`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Volume level set to low`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVolumeMinus /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`volume-minus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVolumeMinus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Decrease volume level`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVolumeMute /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`volume-mute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVolumeMute`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mute volume / volume level set to mute`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVolumePlus /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`volume-plus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVolumePlus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Increase volume level`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconWifi /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wifi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconWifi`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Wifi connection`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconWifiCrossed /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`wifi-crossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconWifiCrossed`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No wifi connection`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 6:Media and devices icons]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}>{`Other`}<a parentName="h3" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Core`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTicket /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ticket`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTicket`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconGlyphEuro /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`glyph-euro`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconGlyphEuro`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconGlyphAt /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`glyph-at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconGlyphAt`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`E-mail, mention user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCake /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`cake`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCake`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of birth`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconCoffeeCupSaucer /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`coffee-cup-saucer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconCoffeeCupSaucer`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Coffee`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconKey /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`key`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconKey`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Key`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconShoppingCart /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`shopping-cart`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconShoppingCart`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Shopping, retail`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconRestaurant /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`restaurant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconRestaurant`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Restaurant`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVaccine /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`vaccine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVaccine`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vaccine`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVirus /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`virus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVirus`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Virus`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 7:Other icons]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "social-media",
      "style": {
        "position": "relative"
      }
    }}>{`Social media`}<a parentName="h3" {...{
        "href": "#social-media",
        "aria-label": "social media permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Icon`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Core`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`React`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Common usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconDiscord /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`discord`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconDiscord`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconFacebook /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`facebook`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconFacebook`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconGoogle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`google`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconGoogle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Google account login`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconInstagram /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`instagram`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconInstagram`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconLinkedin /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`linkedin`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconLinkedin`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconRss /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`rss`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconRss`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconSnapchat /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`snapchat`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconSnapchat`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTiktok /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tiktok`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTiktok`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTwitch /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`twitch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTwitch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconTwitter /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`twitter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconTwitter`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconVimeo /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`vimeo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconVimeo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconWhatsApp /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`whatsapp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconWhatsApp`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconYle /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`yle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconYle`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yle-tunnus account`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><HDS.IconYoutube /></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`youtube`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`IconYoutube`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 8:Social media icons]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      